<template>
  <img alt="Prof. Safadi" height="250" src="./assets/logo.png">
  <p>Welcome to Safadi Puzzler, the new interactive platform for learning and practicing programming and computer topics.
  <br>
  The platform is currently by invitation only. Please get in touch with <a href="https://hanisaf.github.io/scholarly-resume/">me</a> if you would like to join.
</p>
  <p>The following courses are available:
    <br>
    <a href="https://data-engineering.safadi-puzzler.com/">Data engineering</a>
    <br>
    SQL (under development)
    <br>
    Advanced data management and analytics (under development)
    <br>
    Python 1 (under development)
    <br>
    Python 2 (under development)

  </p>
  
  
</template>

<script>

export default {
  name: 'App',
  components: {},
  //data stores variables used in the template
  data: function() {
    return {
    }
  },
  //methods stores functions used in the template
  methods: {
    //run this function when the component is created

  },
  mounted() {
      document.title = "Safadi Puzzler";
      console.log("Safadi Puzzler web app created");
      console.log("Sending a warmup request to the server");
      fetch("https://data-engineering.safadi-puzzler.com")
        .then(response => console.log("Server responded with: " + response.status));
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
